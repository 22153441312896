/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  p {
    @apply font-inter text-base font-normal;
  }
  h1 {
    @apply font-archivo text-5xl font-bold;
  }
  h2 {
    @apply font-archivo text-4xl font-bold;
  }
  h3 {
    @apply font-archivo text-3xl font-bold;
  }
  h4 {
    @apply font-archivo text-2xl font-bold;
  }
  h5 {
    @apply font-archivo text-xl font-bold;
  }
  h6 {
    @apply font-archivo text-lg font-bold;
  }
}
